@import 'color/colors';

.todosSection {
  width: 100%;
  max-width: 1080px;
  padding: 0;
  margin: 40px 0;
  &__header {
    @include text--h1;
    svg {
      font-size: 1.25em;
      margin-top: 4px;
      margin-right: 5px;
      color: inherit;
    }
    @media (max-width: 576px) {
      font-size: 18px;
    }
  }

  @media (max-width: 850px) {
    width: 100%;
    margin-top: 20px;
  }
  @media (max-width: 1250px) {
    &-header {
      color: $dark-75;
      font-weight: 500;
      margin: 4px 20px 4px 0;
    }
  }
  @media (max-width: 768px) {
    &-header {
      line-height: 16px;
      font-size: 14px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 44px minmax(240px, 3.5fr) minmax(120px, 160px) minmax(
        112px,
        140px
      );
    background-color: $white;
    justify-content: stretch;
    justify-items: stretch;
    width: 100%;
    min-width: 800px;
    border-radius: 16px;
    box-shadow: $shadow__table;

    @media (max-width: 876px) {
      min-width: 680px;
    }

    &.isTeam {
      grid-template-columns:
        44px minmax(240px, 3.5fr) minmax(120px, 160px) minmax(120px, 1.5fr)
        minmax(112px, 140px);
    }

    &-header {
      display: flex;
      background-color: $white;
      min-height: 40px;
      top: 0;
      position: sticky;
      border-radius: 16px;

      &-label {
        min-height: 40px;
        background-color: $white;
        border-top-right-radius: 16px;
        cursor: pointer;
        margin: 0;
        padding-right: 8px;
        display: flex;
        align-items: center;
        width: 100%;
        // border-bottom: thin solid $light-95;
        top: 0;
        position: sticky;
        font-size: 14px;
        font-weight: 400;
        color: $text--light;

        svg {
          color: $link;
          margin-left: 2px;
        }
        &:hover {
          color: $dark-75;
          text-decoration: underline;
          svg {
            color: $link--dark;
          }
        }
      }

      &-actions {
        align-items: center;
        backdrop-filter: blur(6px);
        background-color: rgba(255, 255, 255, 0.9);
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
        display: flex;
        height: 100%;
        justify-content: flex-end;
        opacity: 0;
        padding: 0 12px;
        pointer-events: none;
        position: absolute;
        right: 0;
        width: 140px;
        display: flex;

        // animation: animateRight 0.4s;
        &.show {
          opacity: 1;
          animation: animateDown 0.2s;
          pointer-events: initial;
        }
        &.hide {
          opacity: 0;
          animation: animateUp 0.2s;
        }
        .myBtn {
          opacity: 1;
          margin-right: 12px;
        }
        .myBtn--link {
          width: 100px;
          margin-right: 8px;
          svg {
            margin-left: 2px;
          }
        }
      }
    }
    @keyframes animateDown {
      from {
        transform: translate(0, -40px);
        opacity: 0;
      }
      to {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
    @keyframes animateUp {
      from {
        transform: translate(0, 0);
        opacity: inherit;
      }
      to {
        transform: translate(0, -40px);
        opacity: 0;
      }
    }

    &-row {
      display: contents;
      cursor: pointer;
      background-color: inherit;
      &--last {
        .todosSection__list-checkbox {
          border-bottom-left-radius: 16px;
        }
      }
      &:hover {
        background-color: $hover;
        .styled-checkbox {
          display: flex;
        }
        .todosSection__list-actions {
          display: flex;
        }
      }
    }

    &-checkbox {
      background-color: inherit;
      grid-column-start: 1;
      margin: 0;
      padding: 0 8px 0 0;
      display: flex;
      align-items: center;
      width: 44px;
      &-highlight {
        height: 100%;
        width: 8px;
        background-color: transparent;
      }
      .styled-checkbox {
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 8px;
        display: none;
      }

      &.first {
        cursor: pointer;
        margin: 0;
        padding: 6px 8px 4px 8px;
        // border-bottom: thin solid $light-95;
        border-top: none;
        border-radius: 16px;
        top: 0;
        position: sticky;
        .styled-checkbox {
          margin-left: 0;
          display: flex;
        }
      }

      &.checked {
        .styled-checkbox {
          display: flex;
        }
      }
      @media (max-width: 1280px) {
        max-width: 140px;
      }
    }

    &-item {
      background-color: inherit;
      border-top: 2px solid $light-95;
      display: flex;
      flex-direction: column;
      height: 108px;
      justify-content: flex-start;
      margin: 0;
      overflow: hidden;
      padding: 30px 8px 0 0;
      &.first {
        width: 100%;
        h4 {
          @include text--h2;
          margin: 0;
          grid-column: 2;
          display: -webkit-box;
          line-clamp: 2;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow-y: hidden;
        }
        span {
          @include text--h4;
          margin-top: 8px;
        }
      }
      &.last {
        align-items: flex-end;
        position: relative;
        overflow: visible;
        margin-right: 40px;

        span {
          align-self: flex-start;
        }
      }
      h4 {
        background-color: inherit;
        @include text--body1;
        justify-self: flex-start;
        width: 100%;
      }
      span {
        @include text--h4;
        margin-top: 8px;
        &.Low {
          color: $low;
        }
        &.Medium {
          color: $med;
        }
        &.High {
          color: $warning;
        }
      }
    }

    &-actions {
      align-items: center;
      backdrop-filter: blur(1px);
      background-color: $transparentHover;
      display: none;
      height: 98px;
      justify-content: flex-end;
      margin-right: 14spx;
      margin-top: -30px;
      position: absolute;
      width: fit-content;
      .myBtn {
        margin: 0 8px;
        opacity: 1;
        background-color: $white;
      }
    }

    &Toggle {
      grid-column-start: 1;
      grid-column-end: 8;
      margin: 4px 0;
      color: $text--dark-1;
      svg {
        margin-left: 4px;
      }
      &:hover {
        color: $new-primary--light;
      }
    }
  }
}
